<template>
  <Row :gutter="24" class="dashboard-console-grid">
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false">
        <router-link :to="{ path: '/custom/list' }">
          <!--<Icon type="md-people" color="#69c0ff" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/icon/gn1.png" alt="">
          <p>客户管理</p>
        </router-link>
      </Card>
    </Col>
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false">
        <router-link :to="{ path: '/yunli/addserviceProvider' }">
          <!--<Icon type="md-settings" color="#95de64" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/icon/gn2.png" alt="">
          <p>创建服务商</p>
        </router-link>
      </Card>
    </Col>
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false">
        <router-link :to="{ path: '/yunli/serviceProvidersAndServiceProviders' }">
          <!--<Icon type="md-cart" color="#ff9c6e" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/icon/gn3.png" alt="">
          <p>服务商与服务商列表</p>
        </router-link>
      </Card>
    </Col>
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false">
        <router-link :to="{ path: '/yunli/tobecertified' }">
          <!--<Icon type="md-clipboard" color="#b37feb" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/icon/gn4.png" alt="">
          <p>待认证司机</p>
        </router-link>
      </Card>
    </Col>
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false">
        <router-link target="_blank" :to="{ path: '/largeScreen' }">
          <!--<Icon type="md-mail" color="#ffd666" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/icon/gn5.png" alt="">
          <p>运营大屏</p>
        </router-link>
        <!--<div @click="open">-->
        <!--  <img style="width: 94px; height: 94px;" src="@/assets/images/home/icon/gn5.png" alt="">-->
        <!--  <p>运营大屏</p>-->
        <!--</div>-->
      </Card>
    </Col>
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false">
        <router-link :to="{ path: '/yunli/listToBeCertified' }">
          <!--<Icon type="md-card" color="#5cdbd3" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/icon/gn6.png" alt="">
          <p>待认证服务商</p>
        </router-link>
      </Card>
    </Col>
    <!--<Col v-bind="grid" class="ivu-mb" v-auth="['agent-agent-manage']">-->
    <!--  <Card :bordered="false">-->
    <!--    <router-link :to="{ path: $routeProStr + '/agent/agent_manage/index' }">-->
    <!--      <Icon type="md-pricetags" color="#ff85c0" />-->
    <!--      <p>分销管理</p>-->
    <!--    </router-link>-->
    <!--  </Card>-->
    <!--</Col>-->
    <!--<Col v-bind="grid" class="ivu-mb" v-auth="['marketing-store_coupon-index']">-->
    <!--  <Card :bordered="false">-->
    <!--    <router-link :to="{ path: $routeProStr + '/marketing/store_coupon_issue/index' }">-->
    <!--      <Icon type="md-cash" color="#ffc069" />-->
    <!--      <p>优惠券</p>-->
    <!--    </router-link>-->
    <!--  </Card>-->
    <!--</Col>-->
  </Row>
</template>
<script>
export default {
  data() {
    return {
      grid: {
        xl: 4,
        lg: 6,
        md: 6,
        sm: 8,
        xs: 8,
      },
    };
  },
};
</script>
<style lang="less">
.dashboard-console-grid {
  text-align: center;
  .ivu-card-body {
    padding: 0;
  }
  i {
    font-size: 32px;
  }
  a {
    display: block;
    color: inherit;
    padding: 16px;
  }
  p {
    margin-top: 8px;
  }
}
</style>
